import Cookies from "js-cookie";

const role = Cookies.get("user_role");

export const connectLoginUrlForSales = "https://absyzsoftwareconsultingpvtltd--invqa.sandbox.my.salesforce.com/services/oauth2/authorize?client_id=3MVG9Po2PmyYruum3A0jOnx.h6EFMJEnwZDr4ohyz0fHVOWX5zctmLzNfWImV2KAAfXx_NUUfaSDRQf8_1CTn&redirect_uri=https://ape.absyz.com/connect-login&response_type=token"

export const employeeDetailsSales =
"https://absyzsoftwareconsultingpvtltd--invqa.sandbox.my.salesforce.com/services/apexrest/Employeedetails/"


function compareObjects(obj1: any, obj2: any) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (let key of keys1) {
    if (
      typeof obj1[key] === "object" &&
      obj1[key] !== null &&
      !Array.isArray(obj1[key])
    ) {
      if (compareObjects(obj1[key], obj2[key])) {
        return true;
      }
    } else if (Array.isArray(obj1[key])) {
      if (obj1[key]?.length !== obj2[key]?.length) {
        return true;
      }
      for (let i = 0; i < obj1[key]?.length; i++) {
        if (compareObjects(obj1[key][i], obj2[key][i])) {
          return true;
        }
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
  }

  return false;
}

export const compareMultiplePairs = (...pairs: any) => {
  for (let [obj1, obj2] of pairs) {
    if (compareObjects(obj1, obj2)) {
      return true;
    }
  }
  return false;
};


